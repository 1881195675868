import React from "react"
import classes from "./Banner.module.css"

const Banner = ({ content }) => {
  return (
    <div className={classes.root}>
      <h2>{content}</h2>
      <h1>
        Dr. Claudio Maldonado,
        <br /> <span>Traumatólogo</span>
      </h1>
    </div>
  )
}

export default Banner
