import React from "react"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"

const Cirugias = () => {
  const content = [
    {
      title: "Reemplazos Articulares",
      content:
        "Es una de las cirugías más frecuentes y con gran porcentaje de éxito en la especialidad. Genera un cambio notorio en el paciente en horas, aliviando el dolor y mejorando la movilidad. La cirugía consiste en extraer la superficie articular dañada y regularizarla. El cirujano reemplaza estas superficies por componentes protésicos, tanto en cadera, rodilla y otras articulaciones.",
    },
    {
      title: "Cirugía Mini Invasivas y Microquirúrgicas",
      content:
        "Es una técnica que consiste en realizar la misma cirugía convencional pero a través de una muy pequeña incisión (mini abordaje) en lugar de la que se utiliza en la técnica convencional. Es menos agresiva ya que genera un daño mucho menor a los tejidos blandos, músculos y ligamentos circundantes a la cadera. Otras ventajas de esta variante incluyen un menor dolor en el postoperatorio y una rehabilitación más rápida.",
    },
    {
      title: "Cirugía Percutanea de Hallux (Juanete)",
      content:
        "Consiste en una técnica mini invasiva, con anestesia local y en forma ambulatoria para una de las patologías más frecuentes del pie. Otra de las grandes ventajas es su escaso o nulo dolor, rápida recuperación y mínima cicatriz (cosmesis).",
    },
    {
      title: "Fracturas",
      content:
        "La gran frecuencia de lesiones tanto deportivas como las de alta energía provocadas por accidentes de todo tipo, hacen que sea una patología de consulta y tratamiento diario. Para ello tanto los tratamientos médicos como quirúrgicos, aseguran un resultado óptimo según corresponda a cada paciente.",
    },
    {
      title: "Cirugías Ortopédicas",
      content:
        "La correción de deformidades ortopédicas tanto congénitas como adquiridas (artrosis), representan un desafío en el campo de la especialidad, es por eso que contamos con la mejor tecnología y las más avanzadas técnicas quirúrgicas para resolver cada caso en particular.",
    },
    {
      title: "Cirugía Video Artroscópica",
      content:
        "Disponemos de equipamiento de última generación y contamos con la más alta tecnología en aparatología para la realización de todo tipo de técnicas artroscópicas en lesiones meniscales, ligamentarias, artrosis y demás.",
    },
  ]

  const contentJsx = content.map(item => {
    return (
      <div>
        <h3 className={classes.title}>{item.title}</h3>
        <p className={classes.content}>{item.content}</p>
      </div>
    )
  })

  return (
    <ContextProvider>
      <Layout>
        <SEO title="Cirugías" />
        <div className={classes.root}>
          <Banner content="Cirugías" />
          {contentJsx}
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default Cirugias
